<template>
  <a-spin :spinning="spinning">
    <!-- <div v-if="amazonInfo?.refreshTokenExpireState === 'ABOUT_TO_EXPIRE' || amazonInfo?.refreshTokenExpireState === 'EXPIRED'" class="expire-tips">
      <img src="@/assets/images/expire_tips_icon.png" alt="icon" />
      <span>{{ amazonInfo?.refreshTokenExpireState === 'EXPIRED' ? t('Amazon.EXPIRE') : t('Amazon.ABOUTTOEXPIRE') }}</span>
    </div> -->
    <!-- 接口加载完成才展示页面，否则loading -->
    <div v-if="!spinning">
      <div class="navBoxs" v-if="amazonInfo.length > 0">
        <div class="left">Sales Channels</div>
        <div class="right" @click="handleAuthorization">+ {{ t('Amazon.addNewStore') }}</div>
      </div>
      <div class="amazonBox" v-if="amazonInfo.length > 0">
        <div class="amazon-item" v-for="(item, index) in amazonInfo" :key="index">
          <img v-if="item?.refreshTokenExpireState === 'EXPIRED'" class="amazon-bg" src="@/assets/images/amazon/noAmazon_icon.png" alt="amazon" />
          <img v-else class="amazon-bg" src="@/assets/images/amazon_icon.png" alt="amazon" />
          <div class="amazon-title">{{ item.storesBo?.name }}</div>
          <div class="partner-id-box">
            <span>Partner ID</span>
            <div>
              <span class="black">{{ item?.amazonSellingPartnerId }}</span>
              <img src="@/assets/images/copy.png" class="copy_icon" alt="icon" id="PartnerID" @click="copyFun('#PartnerID', { text: item?.amazonSellingPartnerId })" />
            </div>
          </div>
          <div class="connect-date-box">
            <span>{{ t('Amazon.ConnectDate') }}</span>
            <div>
              <span class="black">{{ item?.createAt }}</span>
              <img src="@/assets/images/copy.png" class="copy_icon" alt="icon" id="ConnectDate" @click="copyFun('#ConnectDate', { text: item?.createAt })" />
            </div>
          </div>
          <div class="message-box">
            <div class="Renew-box" v-if="!item.haveSet">
              <img src="~@/assets/images/amazon/waring.png" alt="" />
              <div class="authorizationBox">{{ 'Amazon.noSettingMess' }}！</div>
            </div>
            <div class="Renew-box" v-else-if="item?.refreshTokenExpireState === 'ABOUT_TO_EXPIRE'">
              <img src="~@/assets/images/amazon/waring.png" alt="" />
              <div class="authorizationBox">
                {{ 'Amazon.renewMess' }}！
                <span @click="handleReAuthorize">Renew</span>
              </div>
            </div>
            <div class="expired-box" v-else-if="item?.refreshTokenExpireState === 'EXPIRED'">
              <img src="~@/assets/images/amazon/waring.png" alt="" />
              <span style="color: #000">{{ 'Amazon.authorizationExpired' }}！</span>
            </div>
          </div>
          <div v-if="item?.refreshTokenExpireState === 'EXPIRED'" class="re-authorize-btn" @click="handleReAuthorize(item?.id)">
            {{ t('Amazon.ReAuthorize') }}
          </div>
          <div v-else class="remove-btn" @click="getSetting(item.id)">{{ t('Amazon.settings') }}</div>
          <div class="remove-btn" @click="handleRemove(item.id)">{{ t('Amazon.Remove') }}</div>
        </div>
      </div>
      <div v-else class="amazon-and-wahool-wrap">
        <div class="content-box">
          <img src="@/assets/images/amazon_and_wahool.png" alt="icon" />
          <div class="authorized-title">{{ t('Amazon.authorizedTitle') }}</div>
          <div>{{ t('Amazon.authorizedDescription') }}</div>
          <div class="authorization-button" @click="handleAuthorization">{{ t('Amazon.connectBtn') }}</div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import { copyFun } from '@/utils/util.js'
  import { message } from 'ant-design-vue'
  import { removeAmazonApi } from '@/api/financial.js'
  import { getAmazonAccountList } from '@/api/supplier/amazon/amazon.js'
  let { t, locale } = useI18n()
  const router = useRouter()
  // loading状态
  const spinning = ref(false)
  // 亚马逊绑定信息
  const amazonInfo = ref([])
  //获取绑定店铺列表
  const getStoreListFun = () => {
    getAmazonAccountList().then(res => {
      console.log(res, '获取绑定列表数据')
      amazonInfo.value = res.data
    })
  }
  getStoreListFun()
  /**
   * 删除亚马逊店铺
   */
  const handleRemove = async id => {
    try {
      const result = await removeAmazonApi({ id })
      if (result.code === 200) {
        message.success(`${result?.message}`)
        location.replace('/integrated/Amazon')
      } else {
        message.error(`${result?.message}`)
      }
    } catch (error) {
      message.error(`${error}`)
    }
  }
  /**
   * 重新授权
   */
  const handleReAuthorize = async () => {
    location.href = 'https://sellercentral.amazon.com/apps/manage'
  }
  /**
   * 开始亚马逊授权
   */
  const handleAuthorization = async () => {
    location.href = 'https://sellercentral.amazon.com/selling-partner-appstore/dp/amzn1.sp.solution.a9ba39de-9ef3-459c-abda-84d5f6564aca'
  }

  //跳转到设置页面
  const getSetting = id => {
    router.push({
      path: '/integrated/Settings',
      query: { id }
    })
  }
</script>

<style lang="less" scoped>
  .navBoxs {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    margin-top: 30px;
    .left {
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
    }
    .right {
      border-radius: 8px;
      border: 1px solid #e4e4e4;
      font-size: 14px;
      font-weight: 600;
      background: #000;
      color: #fff;
      // line-height: 40px;
      // width: 150px;
      padding: 10px 20px;
      text-align: center;
      cursor: pointer;
    }
  }
  .amazonBox {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    gap: 15px;
  }
  .message-box {
    width: 100%;
    height: 27px;
    .Renew-box {
      border-radius: 5px;
      border: 1px solid #fae4b8;
      background: #ffffdc;
      height: 27px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 14px;
        width: 16px;
        height: 16px;
      }
      .authorizationBox {
        font-size: 13px;
        font-weight: 500;
        span {
          text-decoration: underline;
          font-size: 13px;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
    .expired-box {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid #fae4b8;
      height: 27px;
      box-sizing: border-box;
      background: rgba(251, 93, 67, 0.75);
      img {
        margin-right: 14px;
        width: 16px;
        height: 16px;
      }
      span {
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
  .amazon-item {
    display: flex;
    width: 316px;
    // width: 30%;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid var(--grayscale-light-gray, #f1f1f1);
    margin-left: 32px;
    margin-top: 30px;
    .amazon-bg {
      width: 100%;
      height: 87px;
    }
  }
  .partner-id-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
    width: 100%;
  }
  .connect-date-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    width: 100%;
  }
  .stores-num {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
  }
  .stores-list {
    width: 100%;
    min-height: 28px;
    border-radius: 2px;
    background: #6b6b6b;
    color: #fff;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; /* 141.667% */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;
    padding-left: 5px;
  }
  .remove-btn {
    display: flex;
    padding: 10px 5px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #f5f5f5;
    color: #000;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.07px;
    cursor: pointer;
  }
  .amazon-title {
    color: var(--grayscale-black, #000);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .copy_icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    cursor: pointer;
  }
  .black {
    color: var(--grayscale-black, #000);
    text-align: right;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px; /* 141.667% */
  }
  .amazon-and-wahool-wrap {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .content-box {
      width: 351px;
    }
    .authorization-button {
      display: flex;
      width: 351px;
      height: 40px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #ff6700;
      color: #fff;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 133.333% */
      margin-top: 40px;
      cursor: pointer;
    }
    .authorized-title {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 43px 0 36px;
    }
    img {
      width: 321px;
      margin: 0 auto;
    }
  }
  .expire-tips {
    width: calc(~'100% - 42px');
    height: 47px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #fb5d43;
    color: #fff;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.07px;
    margin-left: 32px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 15px;
    margin-top: 20px;
    img {
      width: 28px;
      height: 28px;
      margin-right: 5px;
    }
  }
  .re-authorize-btn {
    display: flex;
    padding: 10px 5px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #f5f5f5;
    color: #fff;
    background: #fb5d43;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 20.3px */
    letter-spacing: 0.07px;
    cursor: pointer;
  }
</style>
